@font-face {
  font-family: "Basis Grotesque";
  src:
    url("../public/fonts/basis-grotesque-regular.woff2") format("woff2"),
    url("../public/fonts/basis-grotesque-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Basis Grotesque";
  src:
    url("../public/fonts/basis-grotesque-bold.woff2") format("woff2"),
    url("../public/fonts/basis-grotesque-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: "Basis Grotesque", system-ui, sans-serif;
}

.btn {
  @apply bg-black text-white font-bold py-2 px-4 rounded;
}
